<template>
<!-- 要素落实审核数据页 -->
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <div class="line"></div>
    <!-- 数据列表 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :key="count"
      ref="tableData"
      :dataSource.sync="tableData"
      :api="api"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :getApi="activeNames == 1?'getPlantodo': 'getPlandoto'"
      :webPage="false"
      class="table"
      >
     <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton  @click="jump('/business/essentialFactor/ImplementAudit',scope.row,'E')" content="审核" icon="iconfont iconshenhe1"  v-if="activeNames ==='1'"></IconButton>
        <IconButton @click.prevent="info(scope.row)" content="查看" icon="iconfont iconchakan" v-if="activeNames !=='1'"></IconButton>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/review.config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { factorApi, progressApi } from '@/api/businessApi'
import Storage from '@/utils/storage'
export default {
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: []
    }
  },
  props: {
    count: Number,
    activeNames: String,
    gysData: Array
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return factorApi
    }
  },

  watch: {
    count (val) {
      this.handleFilter()
    },
    activeNames: {
      handler () {
        this.clearParams()
        this.handleFilter()
      },
      deep: true
    }
  },
  mounted () {
    this.clearParams()
    this.handleFilter()
  },
  methods: {
    info (row) {
      Storage.setSession('examine', row)
      Storage.setSession('activeNames', this.activeNames)
      this.$router.push({ path: '/business/essentialFactor/ImplementAudit', query: { type: 'V' } })
    },
    jump (url, row, type) {
      row.businessNo = row.finBusuinessInfoVO.businessNo
      row.businessId = row.finBusuinessInfoVO.keyId
      Storage.setSession('examine', row)
      Storage.setSession('activeNames', this.activeNames)
      if (type === 'E') {
        const params = {
          businessId: row.businessId,
          instanceId: row.instanceId,
          taskId: row.taskId
        }
        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.$router.push({
              path: url,
              query: { type: type }
            })
          }
        })
      } else {
        this.$router.push({
          path: url,
          query: { type: type }
        })
      }
    },
    handleFilter () {
      if (this.activeNames === '2') {
        this.queryParas.reviewStatus = '10'
      } else if (this.activeNames === '3') {
        this.queryParas.reviewStatus = '20'
      }
      this.$nextTick(() => {
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'ELEMENT_IMPLEMENTATION'
      }
      if (this.activeNames === '2') {
        this.queryParas.reviewStatus = '10'
      } else if (this.activeNames === '3') {
        this.queryParas.reviewStatus = '20'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table{
  background: #FFF;
}

.line{
height: 10px;
background: #F8F8F8;
}
</style>
