import BaseSelect from '@/components/common/base-select/base-select.vue'
import { formatDate } from '@/utils/auth/common'
// import { validbusinessNo } from '@/utils/validate'
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      attrs: {
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    }
  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      formatter: row => {
        return context.activeNames === '1' ? row.finBusuinessInfoVO ? row.finBusuinessInfoVO.businessNo : '--' : row.businessNo
      }
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      formatter: row => {
        return context.activeNames === '1' ? row.finBusuinessInfoVO ? row.finBusuinessInfoVO.gysCompanyName : '' : row.gysCompanyName
      }
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      align: 'right',
      formatter: row => {
        const obj = row.finBusuinessInfoVO ? row.finBusuinessInfoVO : {}
        return context.activeNames === '1' ? obj.applyAmount ? obj.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '' : row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : ''
      }
    },
    {
      label: '审核人',
      prop: 'reviewMemberName',
      isHidden: context.activeNames === '1'
    },
    {
      label: '审核时间',
      prop: 'reviewTime',
      isHidden: context.activeNames === '1',
      formatter: row => {
        return formatDate(row.reviewTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '不通过原因',
      prop: 'reviewOpinion',
      minWidth: '150px',
      align: 'left',
      isHidden: context.activeNames !== '3',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
